<template>
  <div class="container_about">
    <div class="margin">
      <div class="flex">
        <h3>About the Wildtech Project</h3>
        <span class="go-back">
          <img
            @click="goBack"
            src="@/assets/icons/creu.svg"
            alt=""
            class="creu"
          />
        </span>
      </div>
      <div class="text">
        <p>
          <strong>WildTech</strong> is a design research project aimed at
          investigating how future technology might support human-nature
          interactions that are profoundly joyful, caring, and fun, led by the
          <a href="https://www.eram.cat" target="_blank">Gamification Group</a>
          (<a href="https://www.eram.cat" target="_blank">Tampere University</a
          >) in collaboration with
          <a href="https://www.eram.cat" target="_blank"
            >Escola Universitària ERAM</a
          >
          (<a href="https://www.udg.edu" target="_blank"
            >Universitat de Girona</a
          >). It is funded by Finland's Academy of Science through the UNITE
          Flagship. Using experimental co-design methods, and displacing our
          design & research practice into the wilderness, we imagine how tech
          can contribute to keeping us connected with (and through) nature.
        </p>
        <p>
          This interactive piece was designed and developed by a team of
          students at Escola Universitària ERAM: Ester Montero (UX/UI design)
          Núria Jordán (Developer). They were supported by Jordi Márquez and
          Ferran Altarriba Bertran.
        </p>
      </div>
      <div class="images">
        <img src="@/assets/logoEram.png" alt="" class="logo" />
        <img src="@/assets/logoGamification.png" alt="" class="logo espai" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>


<style scoped lang="scss">
p {
  color: var(--white);
  text-align: left;
  font-size: 16px;
}

.container_about {
  width: 70%;
  border: 1px solid white;
  border-radius: 20px;

  .margin {
    margin: 2vh 5vh;

    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        color: var(--white);
        text-align: left;
        font-size: 24px;
        font-weight: 500;
      }

      img.creu {
        width: 21px;
        height: 21px;
      }
    }

    .text {
      width: 80%;
      p {
        margin-bottom: 15px;
      }
      a {
        color: var(--white);
      }
    }
  }
}

img.logo {
  height: 100px;
  width: auto;
  text-align: left;
}

img.espai {
  margin-left: 7vh;
}

.images {
  display: flex;
  align-items: left;
  padding: 7vh 0;
}

span {
  cursor: pointer;
}

.paragraf {
  padding-top: 35px;
}
</style>

