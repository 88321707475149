<template>
  <div>
    <svg
      v-if="!isActive"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.375 24.3127V10.1489C11.375 9.30956 12.1272 8.86655 12.5033 8.75H19.1117L23.6248 13.6462V23.9629C23.6248 25.082 22.6578 25.595 22.1742 25.7115H12.5033C11.6007 25.4318 11.375 24.6624 11.375 24.3127Z"
        stroke="white"
      />
      <path
        d="M18.9133 8.75V12.1574C18.9133 12.6623 19.1733 13.7476 20.2131 14.0505H23.6249"
        stroke="white"
      />
      <circle cx="17.5" cy="17.5" r="17" stroke="white" />
    </svg>
    <svg
      v-else
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_438_273)">
        <path
          d="M11.375 24.3127V10.1489C11.375 9.30956 12.1272 8.86655 12.5033 8.75H19.1117L23.6248 13.6462V23.9629C23.6248 25.082 22.6578 25.595 22.1742 25.7115H12.5033C11.6007 25.4318 11.375 24.6624 11.375 24.3127Z"
          fill="white"
          stroke="white"
        />
        <path
          d="M18.9133 8.75V12.1574C18.9133 12.6623 19.1733 13.7476 20.2131 14.0505H23.6249"
          stroke="#0C1028"
        />
        <circle cx="17.5" cy="17.5" r="17" stroke="white" />
      </g>
      <defs>
        <clipPath id="clip0_438_273">
          <rect width="35" height="35" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    isActive: Boolean,
  },
  data() {
    return {
      //active: false
    };
  },
};
</script>
