<template>
  <welcome-word></welcome-word>
</template>

<script>
// @ is an alias to /src
import WelcomeWord from "@/components/WelcomeWord.vue";

export default {
  name: "HomeView",
  components: {
    WelcomeWord,
  },
};
</script>