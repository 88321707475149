<template>
  <div class="overflow">
    <transition :name="animation" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
export default {
  name: "simulationView",
  data() {
    return {
      animation: "slide-down",
    };
  },
  watch: {
    $route(to) {
      console.log(to);
      if (to.name !== "today") {
        this.animation = "slide-up";
      } else {
        this.animation = "slide-down";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.slide-down-enter-active,
.slide-down-leave-active,
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.4s ease-in-out;
}

.slide-down-enter {
  transform: translateY(-100%);
}
.slide-down-leave {
  transform: translateY(0%);
}
.slide-down-leave-to {
  transform: translateY(100%);
}
.slide-down-enter-to {
  transform: translateY(0%);
}
.slide-up-enter {
  transform: translateY(100%);
}
.slide-up-leave {
  transform: translateY(0%);
}
.slide-up-leave-to {
  transform: translateY(-100%);
}
.slide-up-enter-to {
  transform: translateY(0%);
}
.overflow {
  overflow: hidden;
}
</style>