<template id="background">
  <div id="app">
    <div v-if="deviceType!='mobile' && deviceType!='tablet'">
    <div v-if="!loading">
      <router-view />
    </div>
    <div v-else>
      <div class="espera">
        <img src="@/assets/logo.png" alt="" />
        <br />
        <img src="@/assets/espera.png" alt="" />
      </div>
    </div>
    </div>
    <div v-else>
      <div class="mobile_info">
        <div class="logo">
          <img src="@/assets/logo.png" alt="" />
        </div>
        <h1>Hi!</h1>
        <h2>Welcome to Wildtech Project</h2>
        <p>
          In order to enjoy this experience, we recommend you to enter from a computer using the URL below.
        </p>
      
        <p>
          We are waiting for you!
        </p>
        <h3><strong>wildtech.eram.cat</strong></h3>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
                return {
                    deviceType: null,
                };
            },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  beforeCreate() {
    this.$store.dispatch("fetchAllData");
  },
  mounted() {
                this.setDeviceType();
            },
  methods:{
    setDeviceType() {
                    const platform = navigator.platform.toLowerCase();
                    console.log(platform)
                    if (/(android|webos|iphone|ipad|ipod|blackberry|windows phone)/.test(platform)) {
                        this.deviceType = 'mobile';
                    } else if (/mac|win|linux/i.test(platform)) {
                        this.deviceType = 'desktop';
                    } else if (/tablet|ipad/i.test(platform)) {
                        this.deviceType = 'tablet';
                    } else {
                        this.deviceType = 'unknown';
                    }
                },
  }
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap");

:root {
  --background: #001d44;
  --background-gradient: linear-gradient(
    187deg,
    rgba(0, 29, 68, 1) 0%,
    rgba(3, 56, 131, 1) 100%
  );
  --black: #000;
  --white: #fff;
  --font-sans-serif: "Poppins", Helvetica, Arial, sans-serif;
  --border-radius: 15px;
}
* {
  box-sizing: border-box;
}
#app {
  font-family: var(--font-sans-serif);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow: hidden;
}

body {
  // background-color: #0c1028;
  background: var(--background);
  background: var(--background-gradient);
  height: 100vh;
  margin: 0;
  background-attachment: fixed;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-sans-serif);
  color: var(--white);
}
h1 {
  font-size: 30px;
}
h2 {
  font-size: 25px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 18px;
  margin-bottom: 10px;
}
p,
h5,
h6 {
  color: var(--white);
  font-size: 16px;
  margin: 0;
  padding: 0;
}
.container {
  position: relative;
  padding-top: 0px;
  margin: 0px 60px 0;
  // width: 80vw;
  margin: auto;
}
.espera {
  background-color: #2a4040;
  width: 100vw;
  height: 100vh;
}
.background {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
}
.night {
  .menu {
    p {
      color: var(--white) !important;
    }
    button {
      color: var(--white) !important;
      border-color: var(--white) !important;
    }
    .day {
      color: var(--white);
      .bubble {
        border-color: var(--white) !important;
        color: var(--white) !important;
        &:hover {
          background: var(--white);
          p {
            color: var(--black);
          }
        }
      }
    }
    .links {
      svg {
        > path {
          stroke: var(--white) !important;
        }
        circle {
          stroke: var(--white) !important;
          //  stroke-width: 1px;
        }
      }
    }
  }
  .logorelative {
    path,
    circle {
      stroke: var(--white) !important;
    }
  }
  .slide {
    border-color: var(--white) !important;
    button {
      color: var(--white) !important;
      &:hover {
        border-color: var(--white) !important;
        color: var(--white) !important;
      }
      &.active {
        color: black !important;
        background-color: var(--white) !important;
      }
    }
  }
  .aboutus span {
    color: var(--white) !important;
  }
  .instagram {
    .instagram__container {
      &__content {
        &__text p {
          color: var(--white);
        }
        &__icon {
          svg {
            fill: var(--white);
          }
        }
      }
    }
  }
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.4s;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.title-enter-active,
.title-leave-active {
  transition: all 0.3s;
}
.title-enter {
  opacity: 0;
  transform: translateY(-30px);
}
.title-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

//animation Slide Days
.slideIn-enter-active,
.slideIn-leave-active {
  transition: all 0.5s;
  overflow: hidden;
}
.slideIn-enter,
.slideIn-leave-to {
  opacity: 0 !important;
  height: 0 !important;

  //transform: translateX(-300px) !important;
}
.mobile_info{
  padding:20px;
  max-width:600px;
  margin:auto;
  .logo{
    width:200px;
  margin:50px auto;
  img{
    width:100%;
  }
}
h2{
  font-size:18px;
}
a{
  color:white;
  text-decoration:underline;

}
}
</style>
