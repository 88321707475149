<template>
  <transition name="slide-up">
    <div class="about">
      <about-us></about-us>
    </div>
  </transition>
</template>

<script>
// @ is an alias to /src
import AboutUs from "@/components/AboutUs.vue";

export default {
  name: "SaveView",
  components: {
    AboutUs,
  },
};
</script>
<style scoped>
.about {
  margin-left: 20%;
  margin-top: 6%;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.4s ease-in-out;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
}
.slide-up-leave,
.slide-up-enter-to {
  transform: translateY(0%);
}
</style>