<template>
  <div class="unic-card" :class="{ image: this.type == 'images' }">
    <div class="title">
      <h3>{{ title }}</h3>
      <span class="creu">
        <img
          @click="closeIt()"
          src="@/assets/icons/creu.svg"
          alt=""
          class="creu"
        />
      </span>
    </div>
    <div class="center">
      <div class="content">
        <div @click="goTo(type, -1)" class="arrow arrowleft">
          <img src="@/assets/icons/arrowleft.svg" alt="" />
        </div>
        <div class="card" v-if="type == 'cards'">
          <div class="image">
            <img
              :src="'/cards/' + item.frontcover"
              alt=""
              @click="saveElement('cards', item)"
            />
          </div>
          <div class="text">
            <span class="category">{{ item.type }}</span>
            <h4>
              {{ item.title == "-" ? "No title" : item.title }}
            </h4>
            <p>{{ item.information }}</p>
            <p class="the_author">
              By <span>{{ item.author }}</span>
            </p>
            <button class="save" @click="saveElement('cards', item)">
              <book-mark :isActive="checkActive('cards', item._id)"></book-mark>
            </button>
          </div>
        </div>
        <div class="card" v-if="type == 'images'">
          <div class="image">
            <img
              :src="'/images/' + item.image"
              alt=""
              @click="saveElement('images', d)"
            />
            <button class="save" @click="saveElement(type, item)">
              <book-mark :isActive="checkActive(type, item._id)"></book-mark>
            </button>
          </div>
          <!-- <div class="text">
          <span class="category">{{ item.type }}</span>
          <h4>
            {{ item.title == "-" ? "No title" : item.title }}
          </h4>
          <p>{{ item.information }}</p>
          <p class="the_author">
            By <span>{{ item.author }}</span>
          </p>
         
        </div> -->
        </div>
        <div @click="goTo(type, 1)" class="arrow arrowright">
          <img src="@/assets/icons/arroowleft.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BookMark from "../svg/BookMark.vue";
export default {
  name: "ItemUnic",
  components: {
    BookMark,
  },
  props: {
    type: String,
    item: Object,
  },

  data() {
    return {};
  },
  computed: {
    saved() {
      return this.$store.getters.saved;
    },
    title() {
      switch (this.type) {
        case "cards":
          return "Insights on human - nature interactions";
        case "images":
          return "Images";
        default:
          return "No title";
      }
    },
  },
  methods: {
    checkActive(type, id) {
      return this.saved[type].find((e) => e == id) ? true : false;
    },
    saveElement(type, el) {
      if (this.saved[type].includes(el._id)) {
        this.$store.commit("removeSavedItem", {
          type: type,
          id: el._id,
        });
      } else {
        this.$store.commit("addSavedItem", {
          type: type,
          id: el._id,
        });
      }

      localStorage.setItem("savedData", JSON.stringify(this.saved));
    },
    goTo(type, i) {
      console.log(i);
      switch (i) {
        case 1:
          i =
            this.$store.getters[type][
              this.$store.getters[type].indexOf(this.item) + 1
            ];
          break;
        case -1:
          i =
            this.$store.getters[type][
              this.$store.getters[type].indexOf(this.item) - 1
            ];
          break;
      }
      this.$emit("goToItem", { type: type, item: i });
    },
    closeIt() {
      this.$emit("closeIt");
    },
  },
};
</script>

<style scoped lang="scss">
.unic-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  //padding-top: 100px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  &.image .content .card {
    grid-template-columns: 1fr;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      color: var(--white);
    }
    .creu img {
      position: absolute;
      right: 20px;
      top: 20px;
      margin-left: 50px;
      width: 21px;
      height: 21px;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;

    .card {
      background-color: var(--background);
      margin: 0 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      align-items: center;

      border: 1px solid var(--white);
      border-radius: var(--border-radius);
      padding: 20px;
      .image {
        width: 400px;
        img {
          width: 100%;
          height: auto;
          border-radius: var(--border-radius);
          display: block;
        }
      }
      .text {
        width: 320px;
        text-align: left;
        padding h4 {
          color: var(--white);
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 20px;
        }
        span.category {
          font-size: 14px;
          text-transform: lowercase;
          background-color: var(--white);
          border-radius: 10px;
          padding: 0 10px;
        }
        p {
          color: var(--white);
          margin: 0;
        }
        .the_author {
          margin-top: 20px;
          span {
            font-style: italic;
          }
        }
      }
    }
  }
}

.arrow img {
  height: 35px;
  width: 17px;
  cursor: pointer;
}

.save {
  background: none;
  border: none;
  margin-top: 30px;
  // transform: translateY(40px);
  img {
    width: 40px;
    height: 40px;
  }
}

span {
  cursor: pointer;
}
</style>