<template>
  <div>
    <div class="wrapper">
      <!-- :class="{ active: !isFilteredByDay }" -->
      <transition name="fade" mode="out-in">
        <router-view
          :filterDay="filterDay"
          :isFilteredByDay="isFilteredByDay"
          @selectedItem="getSelected"
          @saveIt="saveIt"
          :goTo="goTo"
          :filterText="filterText"
        ></router-view>
      </transition>
    </div>

    <tutorial-information
      class="absolute_tutorial"
      v-if="isTutorialVisible === true"
      @closeTutorial="isTutorialVisible = false"
    ></tutorial-information>
    <transition name="fade" mode="out-in">
      <item-unic
        :item="selected.item"
        :type="selected.type"
        v-if="selected != null"
        @goToItem="getSelected"
        @closeIt="selected = null"
      ></item-unic>
    </transition>
  </div>
</template>

<script>
import TutorialInformation from "@/components/Information/extras/TutorialInformation.vue";
import ItemUnic from "@/components/Information/ItemUnic.vue";

export default {
  name: "InformationView",
  components: {
    TutorialInformation,
    ItemUnic,
  },
  props: {
    filterDay: {
      type: Number,
      default: null,
    },
    filterText: {
      type: String,
      default: null,
    },
    isFilteredByDay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      section: 0,
      textFilter: null,
      isTutorialVisible: true,

      selected: null,
      goTo: 0,
    };
  },
  beforeMount() {
    if (localStorage.getItem("infoTutorial")) {
      this.isTutorialVisible = false;
    } else {
      localStorage.setItem("infoTutorial", true);
      this.$emit("showTutorial");
      this.isTutorialVisible = true;
    }
    if (localStorage.getItem("savedData")) {
      this.$store.commit(
        "setSavedItems",
        JSON.parse(localStorage.getItem("savedData"))
      );
    }
  },
  computed: {
    saved() {
      return this.$store.getters.saved;
    },
    days() {
      return this.$store.getters.days;
    },
  },
  methods: {
    saveIt(data) {
      this.saveElement(data.type, data.e);
    },
    saveElement(type, el) {
      if (this.saved[type].includes(el._id)) {
        this.$store.commit("removeSavedItem", { type, id: el._id });
      } else {
        this.$store.commit("addSavedItem", { type, id: el._id });
      }

      localStorage.setItem("savedData", JSON.stringify(this.saved));
    },

    getSelected(e) {
      console.log(e);
      this.selected = { type: e.type, item: e.item };
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  //padding-top: 120px;
  margin: 0 60px 0 160px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &.active {
    justify-content: flex-end;
  }
}
.absolute_tutorial {
  position: absolute;
  top: 0;
  left: 0;
}
</style>