import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);
const url_api = "https://wildtech.eram.cat/api";
export default new Vuex.Store({
  state: {
    inExperience: false,
    loading: true,
    days: [],
    cards: [],
    notebook: [],
    images: [],
    bag: [],
    activeDay: 0,
    saved: {
      cards: [],
      images: [],
      notebook: [],
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setInExperience(state, ex) {
      state.inExperience = ex;
    },
    setActiveDay(state, dayNumber) {
      state.activeDay = dayNumber;
    },
    setDays(state, days) {
      state.days = days;
    },
    setSavedItems(state, saved) {
      state.saved = saved;
    },
    removeSavedItem(state, { type, id }) {
      let index = state.saved[type].indexOf(id);
      state.saved[type].splice(index, 1);
    },
    addSavedItem(state, { type, id }) {
      state.saved[type].push(id);
    },
    setCards(state, cards) {
      state.cards = cards;
    },
    setNotebook(state, notebook) {
      state.notebook = notebook;
    },
    setImages(state, images) {
      state.images = images;
    },
    setBag(state, bag) {
      state.bag = bag;
    },
  },
  actions: {
    fetchAllData({ dispatch, commit, state }) {
      commit("setLoading", true);
      console.log("Loading before", state.loading);
      let days = dispatch("fetchDays");
      let cards = dispatch("fetchCards");
      let notebook = dispatch("fetchNotebook");
      let images = dispatch("fetchImages");
      let bag = dispatch("fetchBag");

      return Promise.all([days, cards, notebook, images, bag]).then(() => {
        // Both done
        commit("setLoading", false);
        console.log("Loading after", state.loading);
      });
    },
    fetchDays({ state, commit }) {
      console.log("Fetch Days");
      return new Promise((resolve) => {
        const url = url_api + "/days";
        // https://docs.nativescript.org/http.html
        axios
          .get(url)
          .then((res) => {
            console.log(res.data);
            commit("setDays", res.data);
            resolve(state.days);
          })
          .catch((err) => console.log("error", err));
      });
    },
    fetchCards({ state, commit }) {
      console.log("Fetch Cards");
      return new Promise((resolve) => {
        const url = url_api + "/cards";
        // https://docs.nativescript.org/http.html
        axios
          .get(url)
          .then((res) => {
            console.log(res.data);
            commit("setCards", res.data);
            resolve(state.cards);
          })
          .catch((err) => console.log("error", err));
      });
    },
    fetchNotebook({ state, commit }) {
      console.log("Fetch Notebook");
      return new Promise((resolve) => {
        const url = url_api + "/notebooks";
        // https://docs.nativescript.org/http.html
        axios
          .get(url)
          .then((res) => {
            console.log(res.data);
            commit("setNotebook", res.data);
            resolve(state.notebook);
          })
          .catch((err) => console.log("error", err));
      });
    },
    fetchImages({ state, commit }) {
      console.log("Fetch Images");
      return new Promise((resolve) => {
        const url = url_api + "/images";
        // https://docs.nativescript.org/http.html
        axios
          .get(url)
          .then((res) => {
            console.log(res.data);
            commit("setImages", res.data);
            resolve(state.images);
          })
          .catch((err) => console.log("error", err));
      });
    },
    fetchBag({ state, commit }) {
      console.log("Fetch Bag");
      return new Promise((resolve) => {
        const url = url_api + "/bags";
        // https://docs.nativescript.org/http.html
        axios
          .get(url)
          .then((res) => {
            console.log(res.data);
            commit("setBag", res.data);
            resolve(state.bag);
          })
          .catch((err) => console.log("error", err));
      });
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    inExperience(state) {
      return state.inExperience;
    },
    cards(state) {
      return state.cards;
    },
    activeDay(state) {
      return state.activeDay;
    },
    notebook(state) {
      return state.notebook;
    },
    images(state) {
      return state.images;
    },
    bag(state) {
      return state.bag;
    },
    days(state) {
      return state.days;
    },
    saved(state) {
      return state.saved;
    },
  },
  modules: {},
});
