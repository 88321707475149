<template>
  <div>
    <div class="menu">
      <div class="buscador" v-if="headerInformation">
        <p id="day" @click="filteringDay()" :class="{ active: filterDay }">
          <transition name="fade" mode="out-in">
            <span key="1" v-if="hasSlide">Filter by DAY</span>
            <span key="2" v-else>ALL DAYS</span>
          </transition>
        </p>
        <transition name="title" mode="out-in">
          <h2 id="title" :key="title">{{ title }}</h2>
        </transition>
      </div>
      <div v-else>
        <div class="day" v-if="day">
          <div @click="filteringDay()" class="bubble">
            <span>DAY <br />{{ day.point }}</span>
          </div>
          <div class="location">
            <p>{{ day.startingLocation }} - {{ day.endLocation }}</p>
            <p>{{ day.km }}km</p>
          </div>
        </div>
      </div>

      <div class="search" v-if="headerInformation">
        <input
          placeholder="Search"
          @input="emitFilterSearch"
          v-model="message"
        />
        <div class="close" @click="resetSearch()">
          <img src="@/assets/icons/creu.svg" alt="" />
        </div>
      </div>
      <div class="links">
        <span v-if="headerInformation">
          <router-link to="/experience/information/cards" class="icon_day">
            <cards-icon class="logo" :isActive="isIconActive(1)"></cards-icon>
          </router-link>
          <router-link to="/experience/information/notebook" class="icon_day">
            <notebook-icon
              class="logo"
              :isActive="isIconActive(2)"
            ></notebook-icon>
          </router-link>
          <router-link to="/experience/information/images" class="icon_day">
            <photo-icon class="logo" :isActive="isIconActive(3)"></photo-icon>
          </router-link>
        </span>
        <router-link to="/experience/information/saved" class="icon_day">
          <book-mark class="logo" :isActive="isIconActive(4)"></book-mark>
        </router-link>
        <span>
          <transition name="title" mode="out-in">
            <router-link
              key="1"
              to="/experience"
              class="icon_day"
              v-if="headerInformation"
            >
              <experience-icon class="logo"></experience-icon>
            </router-link>

            <router-link
              to="/experience/information"
              class="icon_day"
              key="2"
              v-else
            >
              <document-icon class="logo"></document-icon>
            </router-link>
          </transition>
        </span>
      </div>
    </div>
    <transition name="slideIn">
      <slide-component
        class="absolute"
        @setFilterDay="emitFilterDay"
        v-if="hasSlide"
      ></slide-component>
    </transition>
  </div>
</template>
<script>
import SlideComponent from "./SlideComponent.vue";
import NotebookIcon from "@/components/svg/NotebookIcon.vue";
import CardsIcon from "@/components/svg/CardsIcon.vue";
import PhotoIcon from "@/components/svg/PhotoIcon.vue";
import BookMark from "@/components/svg/BookMark.vue";
import ExperienceIcon from "@/components/svg/ExperienceIcon.vue";
import DocumentIcon from "../svg/DocumentIcon.vue";
export default {
  components: {
    SlideComponent,
    NotebookIcon,
    CardsIcon,
    PhotoIcon,
    BookMark,
    ExperienceIcon,
    DocumentIcon,
  },
  name: "HeaderComponent",
  props: {
    showDays: {
      type: Boolean,
      required: true,
    },
    day: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      section: 1,
      title: "",
      hasSlide: this.showDays,
      filterDay: false,
      message: "",
      headerInformation: false,
    };
  },
  methods: {
    filteringDay() {
      console.log(this.hasSlide);
      this.hasSlide = !this.hasSlide;
      this.$emit("is-filter-day", this.hasSlide);
    },
    isIconActive(i) {
      return this.section == i ? true : false;
    },

    emitFilterSearch(value) {
      console.log("change", value);
      this.$emit("filter-search", this.message);
    },
    resetSearch() {
      this.message = "";
      this.$emit("filter-search", this.message);
    },
    emitFilterDay(value) {
      console.log("emitFilterDay", value);
      this.$emit("filter-day", value);
    },
  },
  watch: {
    "$route.path": {
      handler: function (search) {
        console.log("change route");
        this.$emit("isSimulation", search.includes("simulation"));
        this.headerInformation = search.includes("information");

        let section = search.split("/")[3];
        switch (section) {
          case "cards":
            this.section = 1;
            this.title = "Insights on human-nature interactions";
            break;
          case "notebook":
            this.section = 2;
            this.title = "Methodological reflections";
            break;
          case "images":
            this.section = 3;
            this.title = "Photos";
            break;
          case "saved":
            this.section = 4;
            this.title = "Saved Information";
            break;
          default:
            this.title = "All information";
            this.section = 0;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
  > div {
    width: 40%;
    &:nth-child(2) {
      width: 20%;
    }
  }

  .day {
    cursor: pointer;
    color: var(--black);
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    .bubble {
      border-style: solid;
      border-width: 1px;
      border-color: var(--black);
      background-color: transparent;
      color: var(--black);
      border-radius: 50%;
      padding: 15px;
      font-size: 12px;
      width: 62px;
      height: 62px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: black;
        p {
          color: var(--white);
        }
      }
    }
    .location {
      p {
        text-align: left;
        margin-left: 30px;
        color: var(--black);
      }
    }
  }

  .buscador {
    display: flex;
    flex-wrap: wrap;
    //justify-content: center;
    align-items: center;
    #title {
      font-size: 20px;
      margin-left: 40px;
    }
    #day {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border-color: var(--white);
      border-width: 1px;
      border-style: solid;
      width: 62px;
      height: 62px;
      font-size: 12px;
      &.active {
        background-color: var(--white);
        span {
          color: #0c1028;
        }
      }
    }
    h2,
    p {
      color: var(--white);
      margin: 0;
    }
    button {
      border-style: none;
      background-color: transparent;
      color: var(--white);
    }
  }
  .search {
    position: relative;
    input {
      width: 100%;
      height: 35px;
      border: 1px solid var(--white);
      border-radius: 30px;
      background-color: transparent;
      box-shadow: 1px 1px 6px #0c1028;
      //margin-left: 190px;
      font-size: 14px;
      padding-left: 25px;
      color: var(--white);
    }
    .close {
      position: absolute;
      right: 7px;
      top: 7px;

      img {
        cursor: pointer;
        border-radius: 50%;
        //border: 0.6px solid white;
        padding: 4px;
        transition: all 0.4s;

        width: 20px;
        &:hover {
          border: 0.6px solid white;
        }
      }
    }
  }

  p {
    margin: 0;
  }
  .links {
    display: flex;
    justify-content: flex-end;
    span {
      display: flex;
      justify-content: flex-end;
    }
    .logo {
      width: 60px;
      height: 60px;
      padding-left: 20px;
      color: black;
      ::v-deep svg {
        width: 40px;
        height: 60px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    ::v-deep svg {
      > path {
        stroke: var(--black);
      }
      circle {
        stroke: var(--black);
        stroke-width: 1px;
      }
    }
  }
}
.slide {
  position: fixed;
  top: 120px;
}
.icon_day {
  display: block;
}
</style>