<template>
  <div class="start">
    <div class="background">
      <div class="tamany">
        <div v-if="marca" class="logo">
          <img src="@/assets/wildtech_logo.svg" alt="headphones" />
          <h3>Camino de Santiago</h3>
        </div>

        <div class="textDreta2" v-show="counter == 2 || counter == 1">
          <p>"Hi! Welcome to the Wildtech experience!",</p>
        </div>
        <div
          class="text"
          :class="{
            textPrimer: counter == 2,

            textLlarg: counter == 3,
          }"
        >
          <p>{{ texts[counter - 2] }}</p>
          <div v-if="qr" class="scan">
            <p>Scan me!</p>
            <img src="@/assets/qr.png" />
            <p>Not available yet!</p>
          </div>
        </div>

        <div v-if="value" class="auriculars personatge">
          <img src="@/assets/Frame.svg" alt="headphones" />
        </div>
      </div>
      <div class="footer">
        <hr v-if="linia" />
        <button @click="next()" id="next">Next</button>
        <button id="saltar" @click="menu()">Skip intro</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StartIntro",
  data() {
    return {
      value: false,
      marca: true,
      qr: false,
      linia: false,
      counter: 0,
      //isVisible: false,

      texts: [
        "This interactive piece will allow you to dive deep into the ideas, reflections, and lived experiences I collected during a one-month backpacking trip along El Camino de Santiago where I engaged other backpackers to co-imagine the (hopefully joyful) technological future of our forests.",
        "As you may imagine, during the trip I took many photos and videos. I shared some of them on social media.",
      ],
    };
  },
  // If you'd like to see them while you interact with this piece, please scan the QR code below with your phone.
  methods: {
    next() {
      this.counter++;
      if (this.counter == 0) {
        this.marca = true;
      } else if (this.counter != 0) {
        this.marca = false;
      }
      if (this.counter == 1) {
        this.value = true;
        this.linia = true;
      }
      if (this.counter == 3) {
        this.qr = false;
      }
      if (this.counter != 3) {
        this.qr = false;
      }
      if (this.counter == 4) {
        this.$router.push({ path: "/experience" });
      }
      /* if (this.counter == 4){
        this.isVisible = true;
        this.$refs.videoRef.play();
      }else if (this.counter != 4){
        this.isVisible = false;
      } */
    },
    menu() {
      this.$router.push({ path: "/experience" });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
}
.logo {
  h3 {
    color: var(--black);
    text-align: center;
    font-weight: 400;
  }
}

.footer {
  hr {
    color: black;
    width: 750px;
    border: 1px solid;
    opacity: 1;
    position: fixed;
    left: 0;
    top: 625px;
    animation-duration: 3s;
    animation-name: slidein;
  }
  @keyframes slidein {
    from {
      margin-left: -50%;
      width: 750px;
    }

    to {
      margin-left: 0%;
      width: 750px;
    }
  }
  #saltar {
    color: black;
    text-decoration: none;
    border: none;
    position: fixed;
    right: 50px;
    top: 625px;
    width: 100px;
    font-size: 14px;
  }
}

.background {
  background-color: var(--white);
  width: 100vw !important;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0;
  .container {
    width: 100% !important;
  }
}
.start {
  width: 100vw;
  height: 100vh;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.dark {
    color: var(--black);
    background-color: var(--white);
  }
  button {
    width: 60px;
    height: 60px;
    border: 1.5px solid;
    border-color: var(--white);
    background: none;
    border-radius: 60px;
    margin-top: 50px;
    position: relative;
    z-index: 1;
  }
}

.tamany {
  margin-top: 150px;
}

.text {
  // display: flex;
  // justify-content: center;
  // padding: 0 300px;
  font-size: 16px;
}

.textDreta {
  font-size: 16px;
  animation-duration: 6s;
  animation-name: apareix;
  width: 200px;
  padding: 0 0 0 200px;
}
@keyframes apareix {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.textDreta2 {
  font-size: 16px;
  width: 200px;
  padding: 0 300px;
}

.textLlarg {
  width: 55ch;
  margin-top: 3vh;
  transform: translateX(55vw);
  position: fixed;
}

.textPrimer {
  //(ont-size: 28px;
  font-weight: 400;
  margin-top: 10px;
  width: 55ch;
  transform: translateX(55vw);
}

#next {
  position: fixed;
  left: 82vh;
  top: 625px;
  font-size: 14px;
}

.personatge {
  position: fixed;
  left: 42%;
  top: 245px;
  animation-duration: 3s;
  animation-name: slidepersonatge;
  @keyframes slidepersonatge {
    from {
      margin-left: -50%;
    }

    to {
      margin-left: 0%;
    }
  }
}

.scan img {
  width: 200px;
  height: 200px;
}

.scan p {
  font-weight: 600;
  font-size: 16px;
}

.logo {
  margin-top: 20vh;
}
</style>