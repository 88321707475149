<template>
  <span>
    <svg
      v-if="!isActive"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7778 13.3222H20.8778V13.2222V12.4444C20.8778 12.4383 20.8827 12.4333 20.8889 12.4333H22.6667C22.6728 12.4333 22.6778 12.4383 22.6778 12.4444V13.2222V13.3222H22.7778H23.5556C24.2981 13.3222 24.9 13.9241 24.9 14.6667V22.2222C24.9 22.9647 24.2981 23.5667 23.5556 23.5667H11.7778C11.0353 23.5667 10.4333 22.9647 10.4333 22.2222V14.6667C10.4333 13.9242 11.0353 13.3222 11.7778 13.3222H20.7778ZM20.8889 11.9C20.5882 11.9 20.3444 12.1438 20.3444 12.4444V12.7889H11.7778C10.7407 12.7889 9.9 13.6296 9.9 14.6667V22.2222C9.9 23.2593 10.7407 24.1 11.7778 24.1H23.5556C24.5926 24.1 25.4333 23.2593 25.4333 22.2222V14.6667C25.4333 13.6296 24.5926 12.7889 23.5556 12.7889H23.2111V12.4444C23.2111 12.1438 22.9674 11.9 22.6667 11.9H20.8889Z"
        fill="white"
        stroke="white"
        stroke-width="0.2"
      />
      <circle cx="17.5" cy="17.5" r="17" stroke="white" />
      <rect
        x="12.85"
        y="14.85"
        width="2.3"
        height="0.8"
        rx="0.35"
        stroke="white"
        stroke-width="0.3"
      />
      <circle cx="19.5" cy="18.5" r="2.2" stroke="white" stroke-width="0.6" />
    </svg>

    <svg
      v-else
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7778 13.3222H20.8778V13.2222V12.4444C20.8778 12.4383 20.8827 12.4333 20.8889 12.4333H22.6667C22.6728 12.4333 22.6778 12.4383 22.6778 12.4444V13.2222V13.3222H22.7778H23.5556C24.2981 13.3222 24.9 13.9241 24.9 14.6667V22.2222C24.9 22.9647 24.2981 23.5667 23.5556 23.5667H11.7778C11.0353 23.5667 10.4333 22.9647 10.4333 22.2222V14.6667C10.4333 13.9242 11.0353 13.3222 11.7778 13.3222H20.7778ZM20.8889 11.9C20.5882 11.9 20.3444 12.1438 20.3444 12.4444V12.7889H11.7778C10.7407 12.7889 9.9 13.6296 9.9 14.6667V22.2222C9.9 23.2593 10.7407 24.1 11.7778 24.1H23.5556C24.5926 24.1 25.4333 23.2593 25.4333 22.2222V14.6667C25.4333 13.6296 24.5926 12.7889 23.5556 12.7889H23.2111V12.4444C23.2111 12.1438 22.9674 11.9 22.6667 11.9H20.8889Z"
        fill="white"
        stroke="white"
        stroke-width="0.2"
      />
      <circle cx="17.5" cy="17.5" r="17" stroke="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 15C10 13.8954 10.8954 13 12 13H23C24.1046 13 25 13.8954 25 15V22C25 23.1046 24.1046 24 23 24H12C10.8954 24 10 23.1046 10 22V15ZM13.2 14.7H14.8C15.0761 14.7 15.3 14.9239 15.3 15.2V15.3C15.3 15.5761 15.0761 15.8 14.8 15.8H13.2C12.9239 15.8 12.7 15.5761 12.7 15.3V15.2C12.7 14.9239 12.9239 14.7 13.2 14.7ZM13.2 15C13.0895 15 13 15.0895 13 15.2V15.3C13 15.4105 13.0895 15.5 13.2 15.5H14.8C14.9105 15.5 15 15.4105 15 15.3V15.2C15 15.0895 14.9105 15 14.8 15H13.2ZM21.4 18.5C21.4 19.5493 20.5493 20.4 19.5 20.4C18.4507 20.4 17.6 19.5493 17.6 18.5C17.6 17.4507 18.4507 16.6 19.5 16.6C20.5493 16.6 21.4 17.4507 21.4 18.5ZM22 18.5C22 19.8807 20.8807 21 19.5 21C18.1193 21 17 19.8807 17 18.5C17 17.1193 18.1193 16 19.5 16C20.8807 16 22 17.1193 22 18.5Z"
        fill="white"
      />
      <path d="M20.5 12H23V15H21L20.5 12Z" fill="white" />
    </svg>
  </span>
</template>
<script>
export default {
  name: "PhotoIcon",
  props: {
    isActive: Boolean,
  },
  data() {
    return {
      //active: false
    };
  },
};
</script>
