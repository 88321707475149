<template>
  <span>
    <svg
      v-if="!isActive"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4712 10.3927L16.922 9.93799C17.4943 9.89025 17.997 10.3155 18.0447 10.8878L19.0282 22.6776C19.0759 23.2499 18.6507 23.7525 18.0783 23.8003L12.6275 24.255C12.0552 24.3027 11.5526 23.8775 11.5048 23.3051L10.5214 11.5154C10.4736 10.9431 10.8989 10.4404 11.4712 10.3927ZM11.423 9.81472C10.5315 9.88908 9.86907 10.6721 9.94343 11.5636L10.9269 23.3533C11.0012 24.2449 11.7842 24.9073 12.6757 24.8329L18.1265 24.3782C19.0181 24.3039 19.6805 23.5209 19.6061 22.6294L18.6227 10.8396C18.5483 9.9481 17.7653 9.28567 16.8738 9.36004L11.423 9.81472Z"
        fill="white"
        stroke="white"
        stroke-width="0.2"
      />
      <path
        d="M15.5548 24.1919L15.4423 24.2005L15.4644 24.3112C15.4922 24.4503 15.538 24.5833 15.5995 24.7073C15.8482 25.2093 16.3531 25.5656 16.9549 25.5974L23.1073 25.9227C24.0003 25.9699 24.7687 25.2848 24.8239 24.3923L25.5542 12.58C25.6095 11.6868 24.9295 10.9241 24.0358 10.8768L18.1464 10.5655L18.0289 10.5593L18.0417 10.6762L18.0444 10.7037L19.1082 22.4952C19.1786 23.2757 18.6026 23.9568 17.8213 24.0171L15.5548 24.1919ZM19.6858 22.4431L18.6692 11.1739L24.0052 11.456C24.577 11.4862 25.0107 11.9727 24.9754 12.5442L24.245 24.3565C24.2095 24.9314 23.7131 25.3739 23.1379 25.3435L16.9855 25.0183C16.7185 25.0041 16.4817 24.8906 16.3086 24.7154L17.8659 24.5953C18.9695 24.5102 19.7852 23.5455 19.6858 22.4431Z"
        fill="white"
        stroke="white"
        stroke-width="0.2"
      />
      <circle cx="17.5" cy="17.5" r="17" stroke="white" />
    </svg>

    <svg
      v-else
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="9.97186"
        y="10.3305"
        width="8"
        height="14.3378"
        rx="1"
        transform="rotate(-4.78317 9.97186 10.3305)"
        fill="white"
      />
      <rect
        x="16.6675"
        y="10.9508"
        width="8.48301"
        height="14.3378"
        rx="1"
        transform="rotate(2.75517 16.6675 10.9508)"
        fill="white"
      />
      <path
        d="M11.4711 10.3927L16.922 9.93799C17.4943 9.89025 17.9969 10.3155 18.0447 10.8878L19.0281 22.6776C19.0758 23.2499 18.6506 23.7525 18.0783 23.8003L12.6275 24.255C12.0552 24.3027 11.5525 23.8775 11.5048 23.3051L10.5213 11.5154C10.4736 10.9431 10.8988 10.4404 11.4711 10.3927ZM11.4229 9.81472C10.5314 9.88908 9.86901 10.6721 9.94337 11.5636L10.9268 23.3533C11.0012 24.2449 11.7842 24.9073 12.6757 24.8329L18.1265 24.3782C19.018 24.3039 19.6804 23.5209 19.6061 22.6294L18.6226 10.8396C18.5482 9.9481 17.7653 9.28567 16.8737 9.36004L11.4229 9.81472Z"
        fill="white"
        stroke="white"
        stroke-width="0.2"
      />
      <path
        d="M15.5547 24.1919L15.4423 24.2005L15.4644 24.3112C15.4922 24.4503 15.538 24.5833 15.5994 24.7073C15.8481 25.2093 16.3531 25.5656 16.9549 25.5974L23.1072 25.9227C24.0002 25.9699 24.7686 25.2848 24.8238 24.3923L25.5542 12.58C25.6094 11.6868 24.9294 10.9241 24.0357 10.8768L18.1463 10.5655L18.0289 10.5593L18.0416 10.6762L18.0444 10.7037L19.1081 22.4952C19.1785 23.2757 18.6026 23.9568 17.8213 24.0171L15.5547 24.1919ZM19.6857 22.4431L18.6691 11.1739L24.0051 11.456C24.5769 11.4862 25.0107 11.9727 24.9753 12.5442L24.2449 24.3565C24.2094 24.9314 23.713 25.3739 23.1378 25.3435L16.9855 25.0183C16.7184 25.0041 16.4816 24.8906 16.3086 24.7154L17.8659 24.5953C18.9695 24.5102 19.7852 23.5455 19.6857 22.4431Z"
        fill="white"
        stroke="white"
        stroke-width="0.2"
      />
      <circle cx="17.5" cy="17.5" r="17" stroke="white" />
    </svg>
  </span>
</template>
<script>
export default {
  name: "CardsIcon",
  props: {
    isActive: Boolean,
  },
  data() {
    return {
      //active: false
    };
  },
};
</script>
