<template>
  <div class="tutorial background">
    <div class="flex">
      <div class="sidebar">
        <p>
          This sidebar shows the day you're currently checking. You can change
          it with a click.
        </p>
        <img src="@/assets/icons/ArrowSidebar.svg" alt="" />
      </div>
      <div class="search flex">
        <div>
          <img src="@/assets/icons/ArrowSearch.svg" alt="" />
        </div>
        <div class="search_p">
          <p>You can search specific parts of the data by using keywords.</p>
        </div>
      </div>

      <div class="buttons">
        <div class="recuadre"></div>
        <div class="allBtns">
          <div>
            <img src="@/assets/icons/ArrowMenu.svg" alt="" />
            <p>
              You can use those buttons to filter the kinds of information you
              want to see.
            </p>
          </div>
          <div class="experience">
            <img src="@/assets/icons/ArrowMenu2.svg" alt="" />
            <p>Click here to go back to the interactive adventure mode.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="space">
      <button @click="$emit('closeTutorial', true)">PRESS TO CONTINUE</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TutorialView",
  components: {},

  data() {
    return {
      visible: true,
    };
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeydown);
  },
  methods: {
    handleKeydown(e) {
      if (e.keyCode === 32) {
        this.$emit("closeTutorial", true);
      }
    },
  },
};
</script>


<style scoped lang="scss">
.tutorial {
  position: absolute;
  top: 0;
  left: 0;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
p {
  color: var(--white);
  text-align: left;
  margin: 0;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 100px;

  .sidebar {
    width: 140px;
    margin-top: 110px;

    img {
      float: left;
    }
  }

  .search {
    margin-top: 100px;

    img {
      margin-top: 10px;
    }

    .search_p {
      width: 200px;
      margin-left: 10px;
    }
  }

  .buttons {
    .recuadre {
      border: 1px solid white;
      border-radius: 15px;
      width: 250px;
      height: 70px;
      margin-left: 50px;
    }

    p {
      text-align: center;
      margin: 0;
      width: 180px;
    }

    img {
      margin-top: 10px;
    }
  }

  .experience {
    width: 170px;
    text-align: right;
    //transform: translateX(950px);
    // margin-top: 90px;

    p {
      margin: 0;
      text-align: right;
    }
  }
}
.allBtns {
  display: flex;
}
.space {
  button {
    margin-left: 50px;
    width: 600px;
    height: 50px;
    background-color: var(--white);
    border: none;
    border-radius: 10px;
    color: var(--black);
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }
}
</style>