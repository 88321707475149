<template>
  <div>
    <svg
      v-if="!isActive"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.1032 17.5C34.1032 26.6697 26.6697 34.1032 17.5 34.1032C8.33032 34.1032 0.896825 26.6697 0.896825 17.5C0.896825 8.33032 8.33032 0.896825 17.5 0.896825C26.6697 0.896825 34.1032 8.33032 34.1032 17.5Z"
        stroke="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 9.21531C11 9.0964 11.0951 9 11.2124 9H23.7876C23.9049 9 24 9.0964 24 9.21531V26.7847C24 26.8569 23.9643 26.9243 23.9049 26.9642C23.8455 27.0041 23.7703 27.0111 23.7047 26.9829L20.6485 25.6709L17.5922 24.3588L11.2932 26.9838C11.2277 27.0111 11.153 27.0035 11.0942 26.9636C11.0353 26.9236 11 26.8565 11 26.7847V9.21531ZM11.4248 9.43062V26.4634L17.5126 23.9264C17.5651 23.9045 17.624 23.9049 17.6762 23.9273L23.5752 26.4597V9.43062H11.4248Z"
        stroke="white"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      v-else
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 9.21531C11 9.0964 11.0951 9 11.2124 9H23.7876C23.9049 9 24 9.0964 24 9.21531V26.7847C24 26.8569 23.9643 26.9243 23.9049 26.9642C23.8455 27.0041 23.7703 27.0111 23.7047 26.9829L20.6485 25.6709L17.5922 24.3588L11.2932 26.9838C11.2277 27.0111 11.153 27.0035 11.0942 26.9636C11.0353 26.9236 11 26.8565 11 26.7847V9.21531ZM11.4248 9.43062V26.4634L17.5126 23.9264C17.5651 23.9045 17.624 23.9049 17.6762 23.9273L23.5752 26.4597V9.43062H11.4248Z"
        fill="white"
      />
      <path
        d="M11.4248 9.43062V26.4634L17.5126 23.9264C17.5651 23.9045 17.624 23.9049 17.6762 23.9273L23.5752 26.4597V9.43062H11.4248Z"
        fill="white"
      />
      <path
        d="M34.1032 17.5C34.1032 26.6697 26.6697 34.1032 17.5 34.1032C8.33032 34.1032 0.896825 26.6697 0.896825 17.5C0.896825 8.33032 8.33032 0.896825 17.5 0.896825C26.6697 0.896825 34.1032 8.33032 34.1032 17.5Z"
        stroke="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 9.21531C11 9.0964 11.0951 9 11.2124 9H23.7876C23.9049 9 24 9.0964 24 9.21531V26.7847C24 26.8569 23.9643 26.9243 23.9049 26.9642C23.8455 27.0041 23.7703 27.0111 23.7047 26.9829L20.6485 25.6709L17.5922 24.3588L11.2932 26.9838C11.2277 27.0111 11.153 27.0035 11.0942 26.9636C11.0353 26.9236 11 26.8565 11 26.7847V9.21531ZM11.4248 9.43062V26.4634L17.5126 23.9264C17.5651 23.9045 17.624 23.9049 17.6762 23.9273L23.5752 26.4597V9.43062H11.4248Z"
        stroke="white"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    isActive: Boolean,
  },
  data() {
    return {
      //active: false
    };
  },
};
</script>

<style lang="scss" scoped>
.active {
  fill: white;
}
</style>