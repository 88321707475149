<template>
  <div class="instagram">
    <div class="instagram__container">
      <a
        href="https://www.instagram.com/wildtechresearch/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div class="instagram__container__content">
          <div class="instagram__container__content__icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
              <path
                d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"
              />
            </svg>
          </div>
          <div class="instagram__container__content__text">
            <p>See more on our Instagram account</p>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.instagram {
  position: fixed;
  bottom: 20px;
  left: 30px;

  &__container {
    width: 300px;
    height: 50px;

    cursor: pointer;
    transition: all 0.3s ease-in-out;
    a {
      text-decoration: none;
    }
    &:hover {
      transform: scale(1.05);
      .instagram__container__content__text p {
        opacity: 1;
      }
    }
    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      &__text p {
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        opacity: 0;
        font-size: 12px;
        font-weight: 400;
        color: var(--black);
        margin-left: 10px;
      }
      &__icon {
        width: 30px;
        height: 30px;
        svg {
          width: 100%;
          height: 100%;
          fill: var(--black);
        }
      }
    }
  }
}
</style>