import { render, staticRenderFns } from "./AboutClick.vue?vue&type=template&id=56ff7ba3&scoped=true&"
var script = {}
import style0 from "./AboutClick.vue?vue&type=style&index=0&id=56ff7ba3&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56ff7ba3",
  null
  
)

export default component.exports