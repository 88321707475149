import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import InformationView from "@/views/Experience/Information/InformationView.vue";
import AboutView from "@/views/AboutView.vue";

import WelcomeView from "@/views/WelcomeView.vue";
import ExperienceView from "@/views/Experience/ExperienceView.vue";
import InteractiveView from "@/views/Interactive/InteractiveView.vue";
import SimulationView from "@/views/Experience/Simulation/SimulationView.vue";
//import ExperienceWorld from "../components/ExperienceWorld.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: WelcomeView,
  },
  {
    path: "/experience",
    name: "experience",
    component: ExperienceView,
    redirect: "experience/simulation",
    children: [
      {
        path: "simulation",
        name: "simulationView",
        component: SimulationView,
        children: [
          {
            path: "",
            name: "experienceWorld",
            component: () => import("@/components/ExperienceWorld.vue"),
          },
          {
            path: "today",
            name: "today",
            component: () =>
              import("@/views/Experience/Simulation/TodayView.vue"),
          },
          {
            path: "bag",
            name: "bag",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/components/Experience/Bag/BagWorld.vue"
              ),
          },
          {
            path: "bag/:id",
            name: "bag",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/Experience/Simulation/Bags/BagView.vue"
              ),
          },
        ],
      },
      {
        path: "information",
        name: "InformationView",
        component: InformationView,
        redirect: "information/cards",
        children: [
          {
            path: "saved",
            name: "saved",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/components/Information/SavedWorld.vue"
              ),
          },
          {
            path: "images",
            name: "images",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/components/Information/ImagesWorld"
              ),
          },
          {
            path: "cards",
            name: "cards",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/components/Information/CardsWorld"
              ),
          },
          {
            path: "notebook",
            name: "notebook",

            component: () => import("@/components/Information/NotebookWorld"),
          },
        ],
      },
    ],
  },
  {
    path: "/interactive",
    name: "interactive",
    component: InteractiveView,
    redirect: "/interactive/desktop",
    children: [
      {
        path: "desktop",
        name: "desktop",
        component: () => import("@/views/Interactive/DesktopView.vue"),
      },
      {
        path: "mobile",
        name: "mobile",
        component: () => import("@/views/Interactive/MobileView.vue"),
      },
      {
        path: "tablet",
        name: "tablet",
        component: () => import("@/views/Interactive/TabletView.vue"),
      },
    ],
  },

  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
];

const router = new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  console.log(to);
  if (to.path == "/experience/simulation") {
    store.commit("setInExperience", true);
    console.log("inside simulation");
    document.querySelector("body").classList.remove("night");
    console.log("simulation", document.querySelector("body").classList);
  } else {
    console.log("out of simulation");
    store.commit("setInExperience", false);

    if (!document.querySelector("body").classList.contains("night")) {
      document.querySelector("body").classList.add("night");
    }
  }

  next();
  // ...
  // explicitly return false to cancel the navigation
  //return true;
});
export default router;
