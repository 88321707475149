<template>
  <div class="experience">
    <header :class="{ simulation: simulation }">
      <header-component
        @isSimulation="isSimulation"
        :day="day"
        :showDays="showDays"
        @filter-day="handleFilterDay"
        @filter-search="handleFilterSearch"
        @is-filter-day="handleisFilteringDay"
      ></header-component>
    </header>
    <transition name="fade" mode="out-in">
      <router-view
        :isFilteredByDay="isFilteredByDay"
        :filterDay="selectedDay"
        :filterText="textFilter"
        class="content"
        @showTutorial="showDays = true"
      ></router-view>
    </transition>
    <instagram-click></instagram-click>
    <transition name="slide" mode="out-in">
      <about-click></about-click>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderComponent from "@/components/layout/HeaderComponent.vue";
import AboutClick from "@/components/AboutClick.vue";
import InstagramClick from "@/components/layout/InstagramClick.vue";

export default {
  name: "ExperienceView",
  components: {
    //  SimpleHeaderComponent,
    InstagramClick,
    AboutClick,
    HeaderComponent,
  },
  computed: {
    days() {
      return this.$store.getters.days;
    },
    day() {
      return this.days[this.$store.getters.activeDay];
    },
  },
  data() {
    return {
      intervalDay: null,
      isFilteredByDay: false,
      textFilter: null,
      selectedDay: 0,
      simulation: true,
      showDays: false,
    };
  },
  methods: {
    isSimulation(value) {
      this.simulation = value;
    },
    goToInfo(value) {
      console.log(value);
    },
    handleFilterSearch(text) {
      this.textFilter = text;
      console.log("passing", this.textFilter);
    },
    handleisFilteringDay(value) {
      this.isFilteredByDay = value;
      // this.selectedDay = null;
    },
    handleFilterDay(value) {
      this.selectedDay = value;
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  z-index: 9;
  width: 100vw;
  padding: 30px 60px 0;
  position: relative;
  &.simulation {
    position: absolute;
  }
  .absolute_header {
    position: absolute;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.4s ease-in-out;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(-100%);
}
.slide-leave,
.slide-enter-to {
  transform: translateY(0%);
}
</style>