<template>
  <div class="slide days">
    <div v-for="(d, i) in days" :key="d._id" class="dia">
      <button @click="filter(i)" :class="{ active: checkSelected(i) }">
        {{ d.point }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "SlideComponent",
  computed: {
    days() {
      return this.$store.getters.days;
    },
  },
  data() {
    return {
      filterId: null,
    };
  },
  methods: {
    filter(id) {
      if (this.filterId == null) {
        this.filterId = id;
      } else {
        this.filterId = null;
      }
      console.log("filter", id);
      this.$emit("setFilterDay", id);
      this.$store.commit("setActiveDay", id);
    },
    checkSelected(id) {
      return this.$store.getters.activeDay == id;
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  border-style: none;
  background-color: transparent;
  color: var(--white);
}

.flex button {
  color: grey;
  font-size: 15px;
}

::-webkit-scrollbar {
  display: none;
}

.slide {
  transform: translateX(6px);
  border-radius: 2em;
  border: 1px solid var(--black);
  height: 620px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  width: 50px;
  padding: 0 0px;

  .active {
    width: 100px;
  }
  .dia {
    padding: 5px;
    margin: 0;
    width: 38px;
    height: 38px;

    button {
      color: var(--black);
      width: 38px;
      height: 38px;
      &:hover {
        border-color: var(--black);
        color: var(--black);
        border: 1px;
        border-style: solid;
        border-radius: 50px;
      }

      &.active {
        color: var(--white);
        background-color: var(--black);
        border-radius: 50px;
        &:hover {
          border-color: black;
        }
      }
    }
  }
}
</style>